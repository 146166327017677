.kyc-success-block {
  .title {
    margin: 24px 0;
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  p {
    color: rgb(123, 123, 123);
  }

  button {
    margin-top: 100px;
  }
}
