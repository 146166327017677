.singpass-agreement {
  padding-bottom: 2rem;

  .logo-wrapper {
    display: block;
    text-align: left;
  }

  label {
    font-weight: 700;
    display: block;
    font-size: 13px;
    color:rgb(123, 123, 123);
  }

  .fieldLabel {
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .inputDisabled {
    display: block;
    padding: 0;
    // background: #f5f5f5;
    // border: 1px solid #ccc;
    color: #000000;
    font-size: 15px;
    width: 100%;
  }

  .singpass-agreement-desc {
    margin-top: 16px;
    margin-bottom: 8px;
    color: rgb(123, 123, 123);
    font-size: 13px;
  }

  .disabled-field {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
}

.singpass-invalid {
  margin-top: 16px;
  background: rgba(245, 85, 50, 0.1);
  border-radius: 4px;
  align-items: center;
  padding: 11px 12px;

  svg {
    margin-right: 8px;
  }
}

.customInput {
  height: 58px;
  position: relative;
  border: 1px solid rgb(212, 218, 224);
  margin-top: 16px;

  & + & {
    margin-top: 8px;
  }

  label {
    position: absolute;
    top: 8px;
    left: 12px;
    margin: 0;
  }

  .fieldInput {
    border: none;
    position: absolute;
    top: 30px;
    left: 12px;
    right: 12px;
    margin: 0;
    padding: 0;
    height: auto;
    width: calc(100% - 24px);
    line-height: 0;
    &:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .PhoneInput {
    display: flex;

    input {
      border: none;
      &:focus {
        outline: none;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}