.special-header {
  position: relative;
  margin-top: 24px;

  &-back {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
  h3 {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -0.4px;
    margin-bottom: 24px;
  }
}

.backstep-wrap {
  position: relative;
  .back-btn {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    height: 24px;
  }

  h3 {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -0.4px;
    margin-bottom: 24px;
  }
}
