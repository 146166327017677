.kyc-banned-block {
  .title {
    margin: 24px 0;
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  p {
    color: rgb(123, 123, 123);
  }

  .button {
    margin-top: 100px;
  }

  .outline-btn-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .outline-btn {
      color: #0057ff;
      background: transparent;
      display: inline-flex;
      width: auto;
      margin-top: 32px;
      padding: 0;
      font-weight: bold;
    }
  }

}
