.questionnaire {
  .questionnaire-block {
    padding: 30px 30px;
    border-radius: 20px;
    margin-bottom: 40px;
    box-shadow: 0 24px 24px rgba(0, 0, 0, 0.08);
  }
  .submit-btn {
    margin-top: 100px;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 32rem;
    margin: 0 auto;
    padding: 30px 0 0;
    // .footer-content {
    //   padding: 32px 24px 0;
    // }
  }
}
