.checkbox {
  padding: 0px;
  margin-top: 20px;

  margin-bottom: 20px;

  align-items: center;

  .form-check {
    padding-left: 32px;

    label {
      cursor: pointer;
    }
  }
  .form-check-input {
    width: 20px;
    height: 20px;
    left: 0;
    // top: 50%;
    // transform: translateY(-50%);
    // margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
  }
}
