.comming-soon {
  padding: 0 30px;
  padding-top: 15px;
  padding-bottom: 5px;

  .icon {
    display: block;
    margin: 0.3em auto;
  }
  .heading {
    margin: 0.5em 0;
    font-weight: 600;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .infoBg {
    margin: 0.4em auto;
    border-radius: 4px;
    background-color: rgba(34, 206, 154, 0.1);
    padding: 5px 20px;
    width: fit-content;
    .info {
      opacity: 1;
      text-align: center;
      color: #22ce9a;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .desc {
    font-size: 13px;
    font-weight: normal;
    margin-top: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
  }
  .button {
    margin: 20px auto;
    min-width: 180px;
  }
  .learnmore {
    text-align: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    display: block;
    text-transform: capitalize;
    font-weight: 500;
  }
  .learnmore:hover,
  .learnmore:active,
  .learnmore:focus {
    text-decoration: none;
  }
}
