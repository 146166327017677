.errorMessage{
  margin:1em auto;
  text-align: center;
  color:#ca5400;
  fill:#ca5400;
}
.errorMessage svg{
  margin-right: 8px;
  position: relative;
  top:-2px;
  width:15px;
}