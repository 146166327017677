html,
body {
  height: 100%;
  padding: 10px;
}

#root {
  height: 100%;
  padding: 10px 10px 0;
}

.app {
  width: 100%;
  color: var(--t-1);
}
.legacy-features {
  .cs-base {
    max-width: 32em;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 128px 20px rgba(0, 0, 0, 0.1),
      0px 0px 16px 16px #00000009;

    @media (max-width: 600px) {
      margin-top: 0;
      max-width: unset;
      width: 100%;
      // min-height: 100%;
    }
  }
  .qex-b2b-widget {
    min-height: 100%;
  }
}
.legacy-features.sandbox {
  .cs-base {
    border: 1px solid rgb(255, 0, 255);
  }
}

.top-level-wrapper {
  height: 100%;
}
.top-level-wrapper.legacy-features {
  padding-top: 5em;
  position: relative;
}

// for horizontal
.horizontal .content-padding {
  min-height: auto;
  padding-bottom: 24px;
}
.horizontal .no-menu .quotes-view,
.horizontal .quotes-view {
  min-height: auto;
}

.content-height-sensor {
  max-width: 32rem;
  margin: 0 auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 901px) {
  .horizontal .no-menu .quotes-view,
  .horizontal .quotes-view {
    padding-top: 0;
  }
}

@media (max-width: 600px) {
  .app {
    height: 100%;
  }
  .legacy-features {
    .content-height-sensor {
      height: 100%;
    }
  }
  .top-level-wrapper.legacy-features {
    padding-top: 0;
  }
}
