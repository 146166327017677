.dropdown-component-wrap {
  position: relative;
  border: 1px solid grey;
  border-radius: 8px;
  height: 60px;

  & + & {
    margin-top: 16px;
  }

  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;

    width: 100%;
    height: 100%;
    padding: 0 16px;
  }

  label {
    position: absolute;
    top: 4px;
    left: 16px;
    color: grey;
  }

  svg {
    // margin-right: 16px;
  }

  .dropdown-component-value {
    margin: 16px 16px 0 0;
    display: block;
  }

  .dropdown-component-content {
    position: absolute;
    top: calc(100% + 8px);
    left: -1px;
    right: -1px;
    background: #ffffff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-height: 250px;
    padding: 0 16px;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;

    .dropdown-component-option {
      border-bottom: 1px solid rgb(169, 169, 169);
      padding: 16px 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}