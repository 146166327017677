.custom-modal {
  position: absolute;
  z-index: 40;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .custom-modal-body {
    position: absolute;
    top: 2em;
    left: 0;
    z-index: 50;
    width: 88%;
    // background-color: transparent;
    border-radius: 8px;
    // box-shadow: 0px 0px 20px 10px #888888;
    // box-shadow: 0 40px 24px 0 rgba(9, 11, 15, 0.04);
    box-shadow: 0 8px 16px 0 var(--ui-10);
    margin: 0 auto;
    right: 0;
    text-align: center;
    display: table;
    min-height: 300px;
    max-width: 100%;

    .custom-modal-body-vcenter {
      display: table-cell;
      vertical-align: middle;
      padding: 2em 1em;
    }

    .modal-header {
      margin: 15px;
    }

    .modal-body {
      margin: 5px 15px 5px 15px;
    }

    .modal-footer {
      margin: 0 15px 15px 15px;
    }

    .modal-header .close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #999;
      line-height: 10px;
      cursor: pointer;
    }
    .close-icon {
      cursor: pointer;
      position: absolute;
      right: 1em;
      top: 1em;
      color: currentColor;
    }
    .heading {
      margin-top: 24px;
      font-size: 24px;
      font-weight: 600;
    }
    .description {
      margin-top: 8px;
      font-size: 13px;
    }
  }
  .backdrop {
    position: absolute;
    z-index: 49;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
