.quote-expired-modal {
  strong {
    display: inline;
    font-size: 20px;
    position: relative;
    top: 2px;
    border-bottom: dashed currentColor 2px;
  }
  .custom-modal-body .heading {
    margin-bottom: 0;
    font-size: 20px;
  }
  .custom-modal-body .description {
    margin-top: 0;
  }
  .contentBlock {
    text-align: center;
  }
  .headingDescModalBlock {
    margin-bottom: 24px;
    font-size: 13px;
    margin-top: 8px;
  }
  .newQuoteDataBlock {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .requoteData {
    display: inline;
    margin-left: 10px;
    cursor: pointer;
    .chevron-icon {
      margin-left: 3px;
    }
  }
  .number-increase {
    color: #6c8;
  }
  .number-decrease {
    color: #c44;
  }
  .button {
    display: block;
    margin: 20px auto;
  }
  .btn-link {
    background: none;
    border: none;
    margin: 0 auto;
  }
  .icon-arrow {
    transform: rotate(180deg) scale(0.4);
    position: relative;
    top: -1px;
    left: 5px;
  }
  .info-icon {
    cursor: pointer;
    margin-left: 10px;
  }
  .info-advanced {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    //text-align: left;
  }
  .info-simple {
    max-height: 30px;
    overflow: hidden;
    transition: max-height 0.3s;
  }
  .show-advanced {
    .info-advanced {
      max-height: 120px;
    }
    .info-simple {
      max-height: 0;
    }
    .info-simple {
      display: none;
    }
  }
  @media screen and (max-width: 372px) {
    strong {
      border-bottom: none;
    }
    .show-advanced {
      .info-advanced {
        max-height: 0;
      }
      .info-simple {
        max-height: 30px;
      }
    }
  }
}
