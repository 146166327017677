.under-maintenance {
  background: #fff;

  height: 100%;
  margin: 0;
  font-family: IBM Plex Sans, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, sans-serif;

  min-height: 475px;

  .container {
    text-align: center;
    width: 100%;

    padding-top: 25px;
    @media (min-height: 800px) {
      padding-top: 70px;
    }
    @media (min-height: 1000px) {
      padding-top: 150px;
    }
  }
  .center-panel {
    margin: auto;
    h1 {
      margin-top: 15px;
      font-size: 30px;
    }
    h4 {
      font-size: 20px;
    }
    @media (min-height: 600px) {
      h1 {
        margin-top: 25px;
        font-size: 32px;
        margin-bottom: 25px;
      }
      h4 {
        font-size: 20px;
      }
    }
  }
  .qex-logo {
    width: 200px;
    max-width: 60%;
  }
  .footer {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // left: 0;
    width: 100%;
    display: block;
    padding: 0 16px 16px;
    @media (min-height: 600px) {
      display: block;
    }
    @media (min-height: 800px) {
      padding: 16px;
    }
  }
  .liquid-logo {
    width: 200px;
    max-width: 65%;
    margin: auto;
    display: none;
  }

  .liquid-on-dark {
    .blue & {
      display: block;
    }
    .dark & {
      display: block;
    }
  }

  .liquid-on-light {
    .light & {
      display: block;
    }

    .default & {
      display: block;
    }
  }
}
