.qr-code {
  display: block;
  text-align: center;
  .qr-img {
    margin: 24px auto 16px auto;
    display: inline-block;
    line-height: 0;
    border-radius: 15px;
    background: #fff; //currentColor is black for white theme
    padding: 24px;
    border: 2px solid var(--ui-30);
  }
}
