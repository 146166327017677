.failure-view {
  text-align: center;
  font-size: 14px;
  background: var(--ui-25);
  padding: 16px 24px;
  z-index: 10;
  .title-area {
    padding: 0;
    .title {
      font-size: 23px;
    }
    .subtitle {
      font-size: 14px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;

    svg {
      margin-right: 24px;
    }

    .title {
      flex: 1;
      text-align: left;
    }
  }
}

@media (max-width: 600px) {
  .failure-view {
  
    &-header {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
