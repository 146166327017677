.singpass-signin {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 200px;

  border-top: 1px solid rgb(212, 218, 224);
  padding-top: 24px;
  margin-top: 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // background-color: rgba(210, 210, 210, 0.9);
    background: transparent;
    border: 2px solid rgb(212, 218, 224);
    border-radius: 8px;
    color: #000;
    font-weight: bold;
    &:hover {
      opacity: 0.8;
    }
    img {
      margin-left: 0.5rem;
      height: 30px;
    }
  }
}
