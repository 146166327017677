@import "./mixins";

.cs-subtext {
  color: var(--t-3);
}

.cs-highlight {
  background: var(--ui-25);
}
.cs-interactive {
  background: var(--ui-20);
}
.cs-base {
  // just for legacy
  max-width: 100%;

  @include placeholder {
    color: var(--t-4);
  }
  input:-webkit-autofill {
    // -webkit-text-fill-color: var(--t-1);
    // -webkit-text-fill-color: inherit;
    transition: background-color 5000s ease-in-out 0s;
  }
}
.cs-icon {
  fill: currentColor;
  stroke: currentColor;
  color: var(--icon-default);
}
.cs-fees {
  border-left: 1px solid var(--ui-30);
  .cs-icon {
    background-color: var(--ui-25);
  }
}
.cs-special {
  color: var(--t-1);
  border: 1px solid var(--ui-30);
}
.cs-error {
  color: var(--danger);
  border-color: var(--danger);
}
.core {
  .cs-base {
    .cs-error {
      color: var(--danger);
      border-color: var(--danger);
    }
  }
}
.cs-button {
  font-weight: 500;
  color: var(--white);
  //background: var(--principal);
  border: none;
}
.cs-select {
  background-color: var(--ui-25);
}
